import React from 'react';

import {Trans} from 'react-i18next';

import {BuilderPropsGroup, PropsBuilder} from '../../../../../types';
import {coreScopedKey, usePropsByViewPortSize} from '../../../../../utils';
import {XStack} from '../../../../XStack';
import {EditSectionElement} from '../../../components';
import {PropsBuilderContainer} from '../../../components/PropsBuilderContainer';
import {StateAwareInput} from '../../../components/StateAwareInput';

import {YoutubePlayerElementProps} from './index';

export const _YoutubePlayerpropsBuilder: PropsBuilder<YoutubePlayerElementProps> = ({value: propsValue, onChange}) => {
  const {url} = usePropsByViewPortSize(propsValue);

  const onUpdateProps = (key: string, value: string) => {
    onChange(key, value);
  };

  return (
    <PropsBuilderContainer>
      <EditSectionElement title={<Trans i18nKey="settings.basic.url" />}>
        <XStack alignItems="center" justifyContent="space-between">
          <StateAwareInput
            placeholder="www.example.come"
            value={url || ''}
            onChangeText={(value) => onUpdateProps('url', value)}
          />
        </XStack>
      </EditSectionElement>
    </PropsBuilderContainer>
  );
};

export const YoutubePlayerpropsBuilder: BuilderPropsGroup<YoutubePlayerElementProps> = {
  title: 'content',
  sections: [
    {
      title: coreScopedKey('blocks.YoutubePlayerElement'),
      Content: _YoutubePlayerpropsBuilder,
    },
  ],
};

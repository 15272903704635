import React from 'react';

import {Trans} from 'react-i18next';

import {BuilderPropsGroup, LocalizedTextType, PropsBuilder} from '../../../../types';
import {coreScopedKey, usePropsByViewPortSize} from '../../../../utils';
import {Input} from '../../../Input';
import {LocalizedInput} from '../../../LocalizedInput';
import {EditSectionElement} from '../../components';
import {PropsBuilderContainer} from '../../components/PropsBuilderContainer';

import {InputElementProps} from './index';

export const _InputPropsBuilder: PropsBuilder<InputElementProps> = ({value: propsValue, onChange, onReset}) => {
  const {placeholder} = usePropsByViewPortSize<InputElementProps>(propsValue);
  const onUpdateProps = (key: string, value: string | LocalizedTextType) => {
    onChange(key, value);
  };

  const onResetProps = (keys: string[]) => {
    onReset?.(keys);
  };
  return (
    <PropsBuilderContainer>
      <EditSectionElement title={<Trans i18nKey="placeholder" />} onReset={() => onResetProps(['placeholder'])}>
        <LocalizedInput
          value={placeholder}
          onChange={(value) => {
            onUpdateProps('placeholder', value);
          }}
          input={({localizedValue, onLocalizedValueChange}) => (
            <Input value={localizedValue} onChangeText={(value) => onLocalizedValueChange(value)} />
          )}
        />
      </EditSectionElement>
    </PropsBuilderContainer>
  );
};

export const InputPropsBuilder: BuilderPropsGroup<InputElementProps> = {
  title: 'content',
  sections: [
    {
      title: coreScopedKey('blocks.InputElement'),
      Content: _InputPropsBuilder,
    },
  ],
};

import React, {useMemo} from 'react';

import {useContentElementsResolver} from '../../../context';
import {ViewportSizes, AppContentPage, Block} from '../../../types';
import {usePropsByViewPortSize} from '../../../utils';
import {ErrorBoundary} from '../../ErrorBoundary';
import {PageContainer} from '../../ScreenPage';
import {StateProvider} from '../../StateProvider';
import {View, ViewProps} from '../../View';
import {useStateResolver} from '../hooks/useStateResolver';

import {PageElement} from './components/PageElement';

type Props = ViewProps & {
  page: AppContentPage | null;
  elements?: Record<string, any>;
  viewportSize?: ViewportSizes;
};

export const PagePreview = ({
  page,
  viewportSize = ViewportSizes.MOBILE,
  elements,
  ...props
}: Props): JSX.Element | null => {
  const resolvedProps = useStateResolver(page?.props);
  const pageProps = usePropsByViewPortSize(resolvedProps);
  const builderContentElements = elements || useContentElementsResolver();

  if (!page?.children || !Object.keys(builderContentElements).length) return null;

  const renderPageContent = (children: Block[]): JSX.Element[] => {
    return children.map((child) => {
      return (
        <PageElement
          key={child.id}
          child={child}
          renderPageContent={renderPageContent}
          builderContentElements={builderContentElements}
        />
      );
    });
  };

  const content = useMemo(
    () => (
      <ErrorBoundary>
        <StateProvider state={page?.state}>
          <View {...props} {...pageProps}>
            {renderPageContent(page.children || [])}
          </View>
        </StateProvider>
      </ErrorBoundary>
    ),
    [page, viewportSize],
  );

  if (viewportSize === ViewportSizes.MOBILE) {
    return (
      <PageContainer {...props} {...pageProps}>
        {content}
      </PageContainer>
    );
  }

  return content;
};

import {InputElement} from '../Input';

import {AccordionElement} from './Accordion';
import {ButtonElement} from './Button';
import {ImageElement} from './Image';
import {LinkElement} from './Link';
import {TableElement} from './Table';
import {TextElement} from './Text';
import {VideoElement} from './Video';
import {WebViewElement} from './WebView';
import {YoutubePlayerElement} from './YoutubePlayer';
export {LinkElement} from './Link';
export {AccordionElement, AccordionElementProps} from './Accordion';
export {TextElement} from './Text';
export * from './Image';
export {VideoElement} from './Video';
export {WebViewElement, WebViewElementType} from './WebView';
export {YoutubePlayerElement} from './YoutubePlayer';
export {ButtonElementProps} from './Button';

export const BasicElements = {
  TextElement,
  ImageElement,
  VideoElement,
  LinkElement,
  WebViewElement,
  YoutubePlayerElement,
  ButtonElement,
  AccordionElement,
  TableElement,
  InputElement,
} as const;

import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {View} from '../../../../View';
import {LayoutPropsBuilder} from '../../../components';

import {defaultProps} from './defaulProps';
import {RowElementProps} from './types';

export const Component = ({children, ...props}: RowElementProps, ref) => {
  return (
    <View {...props} ref={ref}>
      {children}
    </View>
  );
};

export const RowComponent = forwardRef(Component);

export const RowElement: BlockDefinition<RowElementProps> = {
  name: coreScopedKey('blocks.RowElement'),
  version: 1,
  id: 'RowElement',
  props: defaultProps,
  children: [],
  Component: RowComponent,
  category: BlockCategory.LAYOUT,
  Icon: 'Columns',
  propsBuilder: [LayoutPropsBuilder],
};

import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {Image} from '../../../../Image';
import {View} from '../../../../View';
import {LayoutPropsBuilder} from '../../../components';

import {defaultProps} from './defaultProps';
import {ImagePropsBuilder} from './PropsBuilder';
import {ImageElementProps} from './types';

export * from './types';

export const Component = (
  {uri, resizeMode, borderRadius, borderWidth, borderColor, ...props}: ImageElementProps,
  ref,
) => {
  return (
    <View {...props} ref={ref}>
      <Image
        source={{uri}}
        borderRadius={borderRadius}
        borderWidth={borderWidth}
        borderColor={borderColor}
        resizeMode={resizeMode}
        width="100%"
        height="100%"
      />
    </View>
  );
};
export const ImageComponent = forwardRef(Component);

export const ImageElement: BlockDefinition<ImageElementProps> = {
  name: coreScopedKey('blocks.ImageElement'),
  version: 1,
  id: 'ImageElement',
  props: defaultProps,
  category: BlockCategory.NODE,
  Component: ImageComponent,
  Icon: 'Image',
  propsBuilder: [ImagePropsBuilder, LayoutPropsBuilder],
};

import {designSpec} from '../../components';
import {
  Applet,
  AppPage,
  AppStructure,
  isAppContentPage,
  isAppServicePage,
  BlockDefinition,
  AppPagesGroup,
  LauncherOptions,
} from '../../types';

import {addMissingServices} from './utils/addMissingServices';
import {filterDisabledWidgets} from './utils/filterDisabledWidgets';
import {shouldDisplayContentPage} from './utils/shouldDisplayContentPage';
import {shouldDisplayServicePage} from './utils/shouldDisplayServicePage';

export function processAppStructure(
  structure: AppStructure,
  enabledApplets: Applet[],
  commonElements: {[key: string]: any},
  showAllWidgets = false,
  isAuthenticated = false,
): AppStructure {
  const enabledAppletsMap = new Map<string, Applet>();
  enabledApplets.forEach((applet) => enabledAppletsMap.set(applet.id, applet));

  addMissingServices(structure.pages, enabledApplets);

  const processedPages = processPages(
    structure.pages,
    enabledAppletsMap,
    commonElements,
    showAllWidgets,
    isAuthenticated,
  );
  const processedAuxiliaryPages = processAuxiliaryPages(
    structure.auxiliaryPages,
    enabledAppletsMap,
    commonElements,
    showAllWidgets,
    isAuthenticated,
  );
  const processedLauncher = processLauncherPages(
    structure.launcher,
    enabledAppletsMap,
    commonElements,
    showAllWidgets,
    isAuthenticated,
  );

  return {
    ...structure,
    pages: processedPages,
    auxiliaryPages: processedAuxiliaryPages,
    launcher: processedLauncher,
  };
}

function processPages(
  pages: AppPage[],
  enabledAppletsMap: Map<string, Applet>,
  commonElements: {[key: string]: any},
  showAllWidgets: boolean,
  isAuthenticated: boolean,
): AppPage[] {
  const processedPages: AppPage[] = [];

  for (const page of pages) {
    if (isAppServicePage(page)) {
      const display = shouldDisplayServicePage(page, enabledAppletsMap, showAllWidgets, isAuthenticated);
      display && processedPages.push(page);
    }

    if (isAppContentPage(page)) {
      const migratedPage = designSpec.migrate(page);

      const pageWithoudDisabledWidgets = filterDisabledWidgets(
        migratedPage,
        enabledAppletsMap,
        commonElements,
        showAllWidgets,
        isAuthenticated,
      );

      const display = shouldDisplayContentPage(pageWithoudDisabledWidgets, showAllWidgets, isAuthenticated);
      display && processedPages.push(pageWithoudDisabledWidgets);
    }
  }

  return processedPages;
}

function processAuxiliaryPages(
  auxiliaryPages: AppPagesGroup[],
  enabledAppletsMap: Map<string, Applet>,
  commonElements: {[key: string]: BlockDefinition},
  showAllWidgets: boolean,
  isAuthenticated: boolean,
): AppPagesGroup[] {
  const processedAuxiliaryPages: AppPagesGroup[] = [];

  for (const group of auxiliaryPages) {
    const processedGroup = {...group, pages: [] as AppPage[]};
    for (const page of group.pages) {
      const migratedPage = designSpec.migrate(page);

      const filteredPage = filterDisabledWidgets(
        migratedPage,
        enabledAppletsMap,
        commonElements,
        showAllWidgets,
        isAuthenticated,
      );

      const display = shouldDisplayContentPage(filteredPage, showAllWidgets, isAuthenticated);
      if (display) processedGroup.pages.push(filteredPage);
    }
    processedAuxiliaryPages.push(processedGroup);
  }

  return processedAuxiliaryPages;
}

function processLauncherPages(
  launcherPages: LauncherOptions,
  enabledAppletsMap: Map<string, Applet>,
  commonElements: {[key: string]: BlockDefinition},
  showAllWidgets: boolean,
  isAuthenticated: boolean,
): LauncherOptions {
  const processedLauncher: LauncherOptions = {};

  for (const [key, launcherPage] of Object.entries(launcherPages)) {
    const migratedPage = designSpec.migrate(launcherPage);

    const filteredPage = filterDisabledWidgets(
      migratedPage,
      enabledAppletsMap,
      commonElements,
      showAllWidgets,
      isAuthenticated,
    );

    const display = shouldDisplayContentPage(filteredPage, showAllWidgets, isAuthenticated);
    if (display) {
      processedLauncher[key] = {...filteredPage};
    }
  }

  return processedLauncher;
}

import React from 'react';

import {Switch} from 'antd';

import {TableElementProps} from '..';
import {coreScopedKey} from '../../../../../../utils';
import {CoreTrans} from '../../../../../ScopedTrans';
import {EditSectionElement} from '../../../../components';

import {ColumnsKeywords} from './ColumnsKeywords';

type Props = {
  onUpdateProps: (key: string, value: unknown) => void;
  filterBy: TableElementProps['filterBy'];
  columns: TableElementProps['columns'];
};

export const TableSearch = ({onUpdateProps, filterBy, columns}: Props) => {
  const update = (updatedFilter) => {
    onUpdateProps('filterBy', updatedFilter);
  };
  return (
    <EditSectionElement
      title={<CoreTrans i18nKey="table.displaySearch" />}
      action={
        <Switch
          checked={!!filterBy}
          onChange={(checked) => update(checked ? columns.map((c) => c.field) : undefined)}
        />
      }>
      {!!filterBy && !!columns.length && (
        <ColumnsKeywords onUpdate={update} columns={columns} placeholder={coreScopedKey('table.searchIn')} />
      )}
    </EditSectionElement>
  );
};

import React, {useMemo, useState} from 'react';

import {Badge} from '../../../Badge';
import {IconView} from '../../../IconView';
import {Input, InputProps} from '../../../Input';
import {ListItem} from '../../../ListItem';
import {Popover} from '../../../Popover';
import {useStateContext, STATE_SYNTAX, GLOBAL_SCOPE_KEY} from '../../../StateProvider';
import {TextArea} from '../../../TextArea';
import {TruncatedTooltipText} from '../../../TruncatedTooltipText';
import {Typography} from '../../../Typography';
import {View} from '../../../View';
import {XStack} from '../../../XStack';

type Props = InputProps & {
  value: string;
  textArea?: boolean;
  onChangeText: (value: string) => void;
};

export const StateAwareInput = ({value, textArea = false, onChangeText, ...props}: Props) => {
  const [showPopover, setShowPopover] = useState(false);
  const [caretPosition, setCaretPosition] = useState(0);

  const context = useStateContext();

  const handleInputChange = (e) => {
    const value = e.target?.value;
    onChangeText(value);
    const lastTwoChars = value.slice(-2);
    if (lastTwoChars === STATE_SYNTAX.PREFIX) {
      setShowPopover(true);
      setCaretPosition(e.target?.selectionStart);
    } else {
      setShowPopover(false);
    }
  };

  const handleStateSelect = (state: string) => {
    const newText =
      value.substring(0, caretPosition - 2) +
      `${STATE_SYNTAX.PREFIX}${state}${STATE_SYNTAX.SUFFIX}` +
      value.substring(caretPosition);
    onChangeText(newText);
    setShowPopover(false);
  };

  const availableVars = useMemo(() => {
    if (!context?.state) return [];

    return Object.entries(context?.state)
      .map(([scopeId, value]) => {
        return value.map((state) => ({
          ...state,
          scopeId,
        }));
      })
      .flat();
  }, [context]);

  return (
    <View position="relative">
      <Popover
        triggerProps={{
          position: 'absolute',
          top: 40,
          left: caretPosition + 20,
          zIndex: 200,
        }}
        contentProps={{padding: '$4'}}
        closeOnClick
        closable
        onOpenChange={setShowPopover}
        open={showPopover}
        trigger={<Typography display="none">{value}</Typography>}>
        {!availableVars?.length ? (
          <ListItem prefix={<IconView icon="Database" />} title="No State available for this component" />
        ) : (
          availableVars.map((v) => (
            <ListItem
              height="$3"
              prefix={<IconView icon="Variable" />}
              suffix={
                <XStack gap="$4">
                  <Badge label={v.type} variant="filled" backgroundColor="$accentLight" />
                  <Badge label={v.scopeId} variant="filled" backgroundColor="$amber-200" />
                </XStack>
              }
              key={v.id}
              title={<TruncatedTooltipText marginRight="$8" text={v.name} maxLength={14} />}
              onPress={() => handleStateSelect(GLOBAL_SCOPE_KEY === v.scopeId ? v.name : v.scopeId + '.' + v.name)}
            />
          ))
        )}
      </Popover>

      {!textArea ? (
        <Input value={value} onChange={handleInputChange} {...props} />
      ) : (
        <TextArea
          textAreaProps={{
            padding: '$4',
            color: '$neutral-600',
            lineHeight: 20,
            fontWeight: '500',
            fontSize: 14,
          }}
          padding="$0"
          value={value}
          onChange={handleInputChange}
          {...props}
        />
      )}
    </View>
  );
};

import {AppContentPage} from '../../../types';

export function shouldDisplayContentPage(
  page: AppContentPage,
  showAllWidgets = false,
  isAuthenticated = false,
): boolean | undefined {
  if (page.public && showAllWidgets && isAuthenticated) return true;

  return !!page.children?.length;
}

import {AppletWidget} from './Applet';
import {AppContentPage} from './AppStructure';
import {IconType} from './IconType';
import {LocalizedTextType} from './LocalizedTextType';

export enum BlockCategory {
  GENERAL = 'GENERAL',
  LAYOUT = 'LAYOUT',
  NODE = 'NODE',
  META = 'META',
}

export const StateTypes = ['string', 'number', 'array'] as const;

export type State = {
  id: string;
  name: string;
  value: string;
  type: typeof StateTypes[number];
};

export interface Block<T = Record<string, any>> {
  id: string;
  definitionId: string;
  definitionVersion: number;
  props: T;
  label?: string;
  children?: Block[];
}

export type BuilderPropsSection<Props> = {
  title: string;
  Content: PropsBuilder<Props>;
};

export interface BuilderPropsGroup<Props> {
  title: string;
  sections: BuilderPropsSection<Props>[];
}

export interface BlockDefinition<Props = {[key: string]: any}> {
  Component: React.ForwardRefExoticComponent<Omit<Props, 'ref'> & React.RefAttributes<unknown>> | React.FC<Props>;
  category: BlockCategory;
  version: number;
  name: LocalizedTextType;
  id: string;
  props: Props;
  children?: Block[];
  appletId?: string;
  Icon?: IconType;
  propsBuilder?: BuilderPropsGroup<Props>[];
  initializer?: PropsInitializer<Props>;
  migrate?: (node: Block) => Block | null;
}

export interface DesignSpec<Props = {[key: string]: any}> {
  version: number;
  Component?: React.FC<Props>;
  blockDefinition: Record<string, BlockDefinition<any>>;
  migrate: (page: AppContentPage) => AppContentPage;
}

export type PropsBuilder<T> = React.FC<{
  value: T;
  onChange: (key: string, value: T[keyof T]) => void;
  onReset: (keys: string[]) => void;
}>;
export type PropsInitializer<T> = React.FC<{onSubmit: (values: T) => void}>;

// Type Guards
export function isBuilderWidgetElement(element: BlockDefinition): element is BlockDefinition & AppletWidget {
  return element && 'appletId' in element;
}

export function isBuilderElement(element: BlockDefinition): element is BlockDefinition {
  return element && !('appletId' in element);
}

// CHECK IF THE BLOCK ELEMNT IS A CONTAINER BASED ON IT'S CHILDREN PROP
export function isLeafElement(node: Block | AppContentPage): node is (Block | AppContentPage) & {children?: undefined} {
  return node && !node.children;
}

export function isSiblingElement(node: Block | AppContentPage): node is (Block | AppContentPage) & {children: Block[]} {
  return !!node.children;
}

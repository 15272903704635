import React from 'react';

import {Switch} from 'antd';

import {BuilderPropsGroup, PropsBuilder} from '../../../../../types';
import {coreScopedKey, usePropsByViewPortSize} from '../../../../../utils';
import {Input} from '../../../../Input';
import {CoreTrans} from '../../../../ScopedTrans';
import {EditSectionElement} from '../../../components';
import {PropsBuilderContainer} from '../../../components/PropsBuilderContainer';

import {TableColumns} from './components/TableColumns';
import {TableRows} from './components/TableRows';
import {TableSearch} from './components/TableSearch';
import {TableSorting} from './components/TableSorting';

import {TableElementProps} from './index';

export const _TablePropsBuilder: PropsBuilder<TableElementProps> = ({value: propsValue, onChange}) => {
  const {
    title,
    filterBy,
    hidePagination,
    pageSize = 5,
    footer,
    columns,
    data = [],
    sortBy,
  } = usePropsByViewPortSize(propsValue);
  const onUpdateProps = (key: string, value: unknown) => {
    onChange(key, value);
  };
  const onPaginationChange = (checked: boolean) => {
    onUpdateProps('hidePagination', !checked);
  };
  return (
    <PropsBuilderContainer>
      <EditSectionElement title={<CoreTrans i18nKey="table.title" />}>
        <Input
          value={title as string}
          onChangeText={(value) => {
            onUpdateProps('title', value);
          }}
        />
      </EditSectionElement>

      <TableColumns columns={columns} onChange={onChange} />
      <TableRows data={data} columns={columns} onChange={onChange} />
      <TableSearch onUpdateProps={onUpdateProps} filterBy={filterBy} columns={columns} />
      <TableSorting onUpdateProps={onUpdateProps} sortBy={sortBy} columns={columns} />
      <EditSectionElement
        title={<CoreTrans i18nKey="table.displayPagination" />}
        action={<Switch checked={!hidePagination} onChange={onPaginationChange} />}
      />

      <EditSectionElement title={<CoreTrans i18nKey="table.pageSize" />}>
        <Input
          value={pageSize >= 0 ? `${pageSize}` : '0'}
          onChangeText={(value) => onUpdateProps('pageSize', +value)}
          type="number"
          disabled={!hidePagination}
        />
      </EditSectionElement>

      <EditSectionElement title={<CoreTrans i18nKey="table.footer" />}>
        <Input value={footer as string} onChangeText={(value) => onUpdateProps('footer', value)} />
      </EditSectionElement>
    </PropsBuilderContainer>
  );
};
export const TablePropsBuilder: BuilderPropsGroup<TableElementProps> = {
  title: 'content',
  sections: [
    {
      title: coreScopedKey('blocks.TableElement'),
      Content: _TablePropsBuilder,
    },
  ],
};

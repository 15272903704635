import {Applet, AppServicePage} from '../../../types';

export function shouldDisplayServicePage(
  page: AppServicePage,
  enabledAppletsMap: Map<string, Applet>,
  showAllWidgets = false,
  isAuthenticated = false,
): boolean | undefined {
  const {appletId, serviceId} = page;
  const applet = enabledAppletsMap.get(appletId);

  const service = applet?.services.find(({id}) => id === serviceId);
  if (!applet || !service) return false;

  if (showAllWidgets) return true;

  return isAuthenticated && applet.canView ? applet.canView(service, applet._context as any) : service.public;
}

import React, {isValidElement} from 'react';

import {Typography} from '../Typography';
import {XStack} from '../XStack';

import {BadgeTypeConfig, BadgeVariantsStyles} from './config';
import {BadgeTypesConfig} from './types';

export * from './types';

export const Badge = ({label, variant = 'outlined', prefix, suffix, type, color, ...props}: BadgeTypesConfig) => {
  const badgeColor = color || BadgeTypeConfig[type || '']?.color;
  const badgeStyles = BadgeVariantsStyles[variant];

  const isElement = isValidElement(label);

  return (
    <XStack borderColor={badgeColor || '$border'} {...badgeStyles} {...props}>
      <XStack marginHorizontal="$2">{prefix}</XStack>
      {isElement ? (
        label
      ) : (
        <Typography variant="body2" fontWeight="600" color={badgeColor || '$neutral-600'}>
          {label}
        </Typography>
      )}

      <XStack marginHorizontal="$2">{suffix}</XStack>
    </XStack>
  );
};

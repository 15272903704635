import {nanoid} from 'nanoid';

import {AccordionElementProps} from './types';

export const defaultProps: AccordionElementProps = {
  sections: [
    {
      id: nanoid(),
      title: 'Section 1',
      content:
        'Your content goes here. Edit styles and content or remove this text From the side bar  / Your content goes here. Edit styles and content or remove this text From the side bar',
    },
    {
      id: nanoid(),
      title: 'Section 2',
      content:
        'Your content goes here. Edit styles and content or remove this text From the side bar  / Your content goes here. Edit styles and content or remove this text From the side bar',
    },
  ],
  maxWidth: '100%',
  flexShrink: 1,
};

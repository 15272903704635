import {ButtonElementProps} from './types';

export const defaultProps: ButtonElementProps = {
  href: 'https://septenant.medadstg.com/',
  value: 'Click me',
  fontSize: 20,
  fontWeight: '400',
  textAlign: 'center',
  margin: 0,
  padding: 0,
};

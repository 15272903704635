import React from 'react';

import {Switch} from 'antd';

import {TableElementProps} from '..';
import {coreScopedKey} from '../../../../../../utils';
import {CoreTrans} from '../../../../../ScopedTrans';
import {EditSectionElement} from '../../../../components';

import {ColumnsKeywords} from './ColumnsKeywords';

type Props = {
  onUpdateProps: (key: string, value: unknown) => void;
  sortBy: TableElementProps['sortBy'];
  columns: TableElementProps['columns'];
};

export const TableSorting = ({onUpdateProps, sortBy, columns}: Props) => {
  const update = (updatedFilter) => {
    onUpdateProps('sortBy', updatedFilter);
  };
  return (
    <EditSectionElement
      title={<CoreTrans i18nKey="table.sorting" />}
      action={
        <Switch checked={!!sortBy} onChange={(checked) => update(checked ? columns.map((c) => c.field) : undefined)} />
      }>
      {!!sortBy && !!columns.length && (
        <ColumnsKeywords columns={columns} onUpdate={update} placeholder={coreScopedKey('table.sortWith')} />
      )}
    </EditSectionElement>
  );
};

import React from 'react';

import {Trans} from 'react-i18next';

import {PropsBuilder} from '../../../../../types';
import {usePropsByViewPortSize} from '../../../../../utils';
import {ViewProps} from '../../../../View';
import {EditSectionElement} from '../../EditSectionElement';
import {SliderInputValuePicker} from '../../SliderInputValuePicker';

import {Container} from './components/Container';

export const Measures: PropsBuilder<ViewProps> = ({value, onChange, onReset}) => {
  const {width, height, minHeight, maxHeight, maxWidth, minWidth} = usePropsByViewPortSize(value);

  return (
    <Container>
      <EditSectionElement title={<Trans i18nKey="settings.layout.width" />} onReset={() => onReset(['width'])}>
        <SliderInputValuePicker max={2000} value={width as number} propName="width" onUpdate={onChange} />
      </EditSectionElement>

      <EditSectionElement title={<Trans i18nKey="settings.layout.minWidth" />} onReset={() => onReset(['minWidth'])}>
        <SliderInputValuePicker max={2000} value={minWidth as number} propName="minWidth" onUpdate={onChange} />
      </EditSectionElement>

      <EditSectionElement title={<Trans i18nKey="settings.layout.maxWidth" />} onReset={() => onReset(['maxWidth'])}>
        <SliderInputValuePicker max={2000} value={maxWidth as number} propName="maxWidth" onUpdate={onChange} />
      </EditSectionElement>

      <EditSectionElement title={<Trans i18nKey="settings.layout.height" />} onReset={() => onReset(['height'])}>
        <SliderInputValuePicker max={2000} value={height as number} propName="height" onUpdate={onChange} />
      </EditSectionElement>

      <EditSectionElement title={<Trans i18nKey="settings.layout.minHeight" />} onReset={() => onReset(['minHeight'])}>
        <SliderInputValuePicker max={2000} value={minHeight as number} propName="minHeight" onUpdate={onChange} />
      </EditSectionElement>

      <EditSectionElement title={<Trans i18nKey="settings.layout.maxHeight" />} onReset={() => onReset(['maxHeight'])}>
        <SliderInputValuePicker max={2000} value={maxHeight as number} propName="maxHeight" onUpdate={onChange} />
      </EditSectionElement>
    </Container>
  );
};

import {ImageElementProps} from './types';

export const defaultProps: ImageElementProps = {
  maxHeight: 300,
  minHeight: 300,
  height: 300,
  minWidth: 250,
  uri: 'https://bgr.com/wp-content/uploads/2022/03/AdobeStock_194080021.jpeg?quality=82&strip=all',
  borderRadius: 0,
  resizeMode: 'cover',
  flex: 1,
};

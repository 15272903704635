import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';

import {coreScopedKey} from '../../../../../../utils';
import {Alert} from '../../../../../Alert';
import {Button} from '../../../../../Button';
import {Dialog} from '../../../../../Dialog';
import {IconView} from '../../../../../IconView';
import {Input} from '../../../../../Input';
import {CoreTrans, Trans} from '../../../../../ScopedTrans';
import {Column} from '../../../../../Table';
import {useShowToast} from '../../../../../Toast';
import {Typography} from '../../../../../Typography';
import {XStack} from '../../../../../XStack';
import {YStack} from '../../../../../YStack';
import {EditSectionElement} from '../../../../components';

export type Props = {
  data: any;
  columns: Column<any>[];
  onChange: (key: string, value: unknown) => void;
};

export const TableRows = ({data, columns, onChange}: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [row, setRow] = useState<Record<string, unknown>>({});
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const toast = useShowToast();
  const {t} = useTranslation();
  const onUpdateProps = (key: string, value: unknown) => {
    onChange(key, value);
  };
  const handleEditRow = () => {
    const newRows = data.toSpliced(selectedRowIndex as number, 1, row);
    onUpdate(newRows);
    setIsEdit(false);
    setRow({});
  };
  const handleAddNewRow = () => {
    const newRows = [...data, row];
    onUpdate(newRows);
  };
  const onUpdate = (rows) => {
    onUpdateProps('data', rows);
    onUpdateProps('totalItems', rows.length);

    setOpenModal(false);
    setSelectedRowIndex(null);
  };
  const handleDeleteColumn = (index: number) => {
    const newRows = data.filter((r, rowIndex) => rowIndex !== index);
    onUpdate(newRows);
  };

  return (
    <YStack>
      <EditSectionElement
        title={<CoreTrans i18nKey="table.rows" />}
        action={
          <Button
            prefix={<IconView icon="PlusCircle" />}
            variant="empty"
            height="auto"
            onPress={() => {
              if (!columns.length) {
                return toast({title: coreScopedKey('table.addRowError'), preset: 'error'});
              }
              setOpenModal(true);
            }}>
            <Trans i18nKey="action.add" />
          </Button>
        }>
        {!!data.length && (
          <YStack
            borderWidth="$0.5"
            borderColor="$border"
            maxHeight="$15"
            padding="$8"
            gap="$8"
            borderRadius="$8"
            overflow="scroll">
            {data.map((row, index) => (
              <YStack key={index}>
                <XStack
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius="$8"
                  padding="$2"
                  paddingHorizontal="$8"
                  borderWidth="$0.5"
                  borderColor="$border">
                  <Typography fontSize="$16">
                    <CoreTrans i18nKey="table.row" /> {index + 1}
                  </Typography>
                  <XStack alignItems="center" gap="$4">
                    <Button
                      icon={<IconView icon="Edit" />}
                      variant="empty"
                      onPress={() => {
                        setRow({...row});
                        setOpenModal(true);
                        setIsEdit(true);
                        setSelectedRowIndex(index);
                      }}
                    />

                    <Alert
                      title={t('action.delete')}
                      trigger={<Button icon={<IconView icon="Trash" color="$red-400" />} variant="empty" />}
                      onAccept={() => handleDeleteColumn(index)}
                      type="danger"
                      content={t('builder.deleteStateMsg')}
                    />
                  </XStack>
                </XStack>
              </YStack>
            ))}
          </YStack>
        )}
        <Dialog
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            setIsEdit(false);
            setRow({});
          }}
          title={`${isEdit ? 'Edit' : 'Add'} Row`}
          sideBarMode={false}
          content={
            <YStack gap={10} paddingHorizontal="$16">
              {columns.map((column, index) => (
                <Input
                  key={index}
                  onChangeText={(value) => {
                    setRow({
                      ...row,
                      [column?.field]: value,
                    });
                  }}
                  value={row?.[column?.field as string] as string}
                  label={column.label}
                />
              ))}

              <Button
                width="$7"
                alignSelf="flex-end"
                onPress={isEdit ? handleEditRow : handleAddNewRow}
                suffix={<IconView icon="PlusCircle" />}
                type="success">
                <Trans i18nKey={isEdit ? 'action.edit' : 'action.add'} />
              </Button>
            </YStack>
          }
        />
      </EditSectionElement>
    </YStack>
  );
};

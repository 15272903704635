import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {View, ViewProps} from '../../../../View';
import {LayoutPropsBuilder} from '../../../components';

export type PageElementProps = ViewProps;

const defaultProps: PageElementProps = {
  padding: 12,
  gap: 12,
  position: 'relative',
  minHeight: '100%',
  overflow: 'scroll',
  borderRadius: 8,
  backgroundColor: '$background',
};
export const Component = ({children, ...props}: PageElementProps, ref) => {
  return (
    <View {...props} ref={ref}>
      {children}
    </View>
  );
};

export const PageComponent = forwardRef(Component);

export const PageElement: BlockDefinition<PageElementProps> = {
  name: coreScopedKey('blocks.PageElement'),
  version: 1,
  id: 'PageElement',
  category: BlockCategory.LAYOUT,
  props: defaultProps,
  children: [],
  Component: PageComponent,
  Icon: 'Columns',
  propsBuilder: [LayoutPropsBuilder],
};

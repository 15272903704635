import {GLOBAL_SCOPE_KEY, useStateContext, STATE_PATTERN, STATE_SYNTAX} from '../../StateProvider';

export const useStateResolver = (props?: Record<string, any>, editMode = false) => {
  const context = useStateContext();
  const allVariables = context?.state;

  if (!props) return {};

  const getVariableValue = (scopeId: string, varName: string): string => {
    const effectiveScopeId = scopeId || GLOBAL_SCOPE_KEY;
    const scopeVariables = allVariables?.[effectiveScopeId];
    const notFoundText = `${STATE_SYNTAX.PREFIX}${
      effectiveScopeId === GLOBAL_SCOPE_KEY ? '' : effectiveScopeId + '.'
    }${varName}${STATE_SYNTAX.SUFFIX}`;

    if (!scopeVariables) return notFoundText;

    const variable = scopeVariables.find((v) => v.name === varName);
    return variable ? variable.value : notFoundText;
  };

  const resolveValue = (value: any): any => {
    if (typeof value === 'string') {
      return value.replace(STATE_PATTERN, (_, scopeIdOrVarName, varName) => {
        const scopeId = varName ? scopeIdOrVarName : GLOBAL_SCOPE_KEY;
        const variableName = varName || scopeIdOrVarName;

        if (!editMode) {
          return getVariableValue(scopeId, variableName);
        } else {
          return `${STATE_SYNTAX.PREFIX}${scopeId}.${variableName}${STATE_SYNTAX.SUFFIX}`;
        }
      });
    } else if (Array.isArray(value)) {
      return value.map(resolveValue);
    } else if (typeof value === 'object' && value !== null) {
      return Object.keys(value).reduce((acc, key) => {
        acc[key] = resolveValue(value[key]);
        return acc;
      }, {} as Record<string, any>);
    }
    return value;
  };

  return Object.keys(props).reduce((acc, key) => {
    acc[key] = resolveValue(props[key]);
    return acc;
  }, {} as Record<string, any>);
};

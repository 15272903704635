import {AppContentPage, Block} from '../../../types';
import {GLOBAL_SCOPE_KEY} from '../../StateProvider';
import {designSpec} from '../elements';

function migrateBlock(block: Block): Block {
  const blockDef = designSpec.blockDefinition[block.definitionId];

  if (blockDef && block.definitionVersion < blockDef.version) {
    return blockDef.migrate ? blockDef.migrate(block) || block : block;
  }

  if (block.children && block.children.length > 0) {
    const updatedBlock = {
      ...block,
      children: block.children.map(migrateBlock),
    };

    return updatedBlock;
  }

  return block;
}

export function migratePage(page: AppContentPage): AppContentPage {
  if (page.specVersion < designSpec.version) {
    const newPage: AppContentPage = {
      ...page,
      specVersion: designSpec.version,
    };
    return designSpec.migrate ? designSpec.migrate(newPage) || page : page;
  }

  const updatedPage = {
    ...page,
    state: page.state || {[GLOBAL_SCOPE_KEY]: []},
    specVersion: designSpec.version,
    children: page.children?.map?.(migrateBlock),
  };

  return updatedPage;
}

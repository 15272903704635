import {Applet, AppPage, isAppServicePage} from '../../../types';
import {createAppServicePage} from '../../appStructureFactory';

export function addMissingServices(pages: AppPage[], enabledApplets: Applet[]) {
  const alreadyAdded = new Set();
  pages.forEach((page) => isAppServicePage(page) && alreadyAdded.add(page.serviceId));

  enabledApplets.forEach((applet) => {
    applet.services.forEach((service) => {
      if (!alreadyAdded.has(service.id)) {
        pages.push(createAppServicePage(applet.id, service.id));
        alreadyAdded.add(service.id);
      }
    });
  });
}

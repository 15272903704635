import {RowElementProps} from './types';

export const defaultProps: RowElementProps = {
  position: 'relative',
  borderRadius: 4,
  paddingHorizontal: 3,
  paddingBottom: 3,
  padding: 20,
  maxWidth: '100%',
  gap: 6,
  flexDirection: 'row',
  alignItems: 'center',
  $md: {
    flexWrap: 'wrap',
  },
  $sm: {
    flexWrap: 'wrap',
  },
};

import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {Button} from '../../../../Button';
import {Link} from '../../../../Link';
import {LocalizedText} from '../../../../LocalizedText';
import {Typography} from '../../../../Typography';
import {LayoutPropsBuilder} from '../../../components';

import {defaultProps} from './defaultProps';
import {ButtonPropsBuilder} from './PropsBuilder';
import {ButtonElementProps} from './types';

export * from './types';

export const Component = ({value, ...props}: ButtonElementProps, ref) => {
  return (
    <Button $sm={{width: '100%'}} {...props} ref={ref}>
      <Link href={props.href} textDecorationLine="none">
        <Typography>
          <LocalizedText value={value} />
        </Typography>
      </Link>
    </Button>
  );
};

export const ButtonComponent = forwardRef(Component);

export const ButtonElement: BlockDefinition<ButtonElementProps> = {
  name: coreScopedKey('blocks.ButtonElement'),
  version: 1,
  id: 'ButtonElement',
  props: defaultProps,
  Component: ButtonComponent,
  category: BlockCategory.NODE,
  Icon: 'SquareGantt',
  propsBuilder: [ButtonPropsBuilder, LayoutPropsBuilder],
};

import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {Link} from '../../../../Link';
import {LocalizedText} from '../../../../LocalizedText';
import {Typography} from '../../../../Typography';
import {View} from '../../../../View';
import {LayoutPropsBuilder} from '../../../components';

import {defaultProps} from './defaultProps';
import {LinkPropsBuilder} from './PropsBuilder';
import {LinkElementProps} from './types';

export const Component = ({value, color, gap, fontSize, lineHeight, fontWeight, ...props}: LinkElementProps, ref) => {
  return (
    <View alignItems="center" justifyContent="flex-start" {...props} ref={ref}>
      <Link href={props.href} textDecorationLine="none" flexDirection="row" flex={0} gap={gap}>
        <Typography color={color} fontSize={fontSize} lineHeight={lineHeight} fontWeight={fontWeight}>
          <LocalizedText value={value} />
        </Typography>
      </Link>
    </View>
  );
};

export const LinkComponent = forwardRef(Component);

export const LinkElement: BlockDefinition<LinkElementProps> = {
  name: coreScopedKey('blocks.LinkElement'),
  version: 1,
  id: 'LinkElement',
  props: defaultProps,
  category: BlockCategory.NODE,
  Component: LinkComponent,
  Icon: 'ExternalLink',
  propsBuilder: [LinkPropsBuilder, LayoutPropsBuilder],
};

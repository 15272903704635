import {BadgeStatusConfig, BadgeVariantsConfig} from '../types';

export const BadgeTypeConfig: BadgeStatusConfig = {
  info: {
    color: '$blue-400',
  },
  success: {
    color: '$green-400',
  },
  danger: {
    color: '$red-400',
  },
  warning: {
    color: '$amber-400',
  },
};

export const BadgeVariantsStyles: BadgeVariantsConfig = {
  outlined: {
    minHeight: 26,
    flex: 0,
    borderWidth: '$0.5',
    borderRadius: '$rounded-full',
    alignItems: 'center',
    paddingHorizontal: '$12',
    paddingVertical: '$4',
    gap: '$6',
  },
  filled: {
    height: 22,
    minWidth: '$6',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '$rounded-full',
    padding: '$2',
    paddingHorizontal: '$8',
  },
};

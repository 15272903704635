import React from 'react';

import {Tooltip} from '../Tooltip';
import {Typography, TypographyProps} from '../Typography';

export type TruncatedTooltipTextProps = TypographyProps & {
  text: string;
  maxLength?: number;
};

export const TruncatedTooltipText = ({text, maxLength = 8, ...props}: TruncatedTooltipTextProps) => {
  const truncateText = (str: string, length: number) => (str.length > length ? `${str.slice(0, length)}...` : str);

  return (
    <Tooltip content={<Typography color="$white">{text}</Typography>} hide={text.length <= maxLength}>
      <Typography {...props}>{truncateText(text, maxLength)}</Typography>
    </Tooltip>
  );
};

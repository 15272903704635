import {
  AppContentPage,
  Applet,
  AppletWidget,
  Block,
  isBuilderWidgetElement,
  isLeafElement,
  BlockDefinition,
} from '../../../types';

export function filterDisabledWidgets(
  page: AppContentPage,
  enabledAppletsMap: Map<string, Applet>,
  commonElements: {[key: string]: BlockDefinition},
  showAllWidgets = false,
  isAuthenticated = false,
): AppContentPage {
  const isWidgetEnabled = (widgetElement: AppletWidget): boolean => {
    const applet = enabledAppletsMap.get(widgetElement.appletId);
    const appletIsDisabled = !enabledAppletsMap.has(widgetElement.appletId);
    const widget = applet?.widgets.find(({id}) => id === widgetElement.id);

    if (appletIsDisabled) return false;
    if (showAllWidgets) return true;

    if (applet && widget) {
      return !!(isAuthenticated ? applet?.canView?.(widget, applet._context as any) : !!widget.public);
    }
    return false;
  };

  const traverseNode = (node: Block): boolean | undefined => {
    const element = commonElements[node.definitionId];

    if (!element) return false;

    if (isLeafElement(node)) {
      if (isBuilderWidgetElement(element)) return isWidgetEnabled(element);
      return true;
    }

    if (node.children && node.children.length) {
      node.children = node.children.filter(traverseNode);
    }

    return !!element && node.children && node.children.length > 0;
  };

  const filteredChildren = page?.children?.filter((child) => traverseNode(child));

  return {
    ...page,
    children: filteredChildren,
  };
}

import React, {createContext, ReactNode, useContext, useState, useEffect} from 'react';

import {State} from '../../types';

type StateProviderProps = {
  children: ReactNode;
  state: Record<string, State[]>;
};

export interface StatesContextProps {
  state: Record<string, State[]>;
  createState: (newState: State, scopeId: string) => void;
  editState: (id: string, newValue: State, scopeId: string) => void;
  deleteState: (id: string) => void;
}

const StateContext = createContext<StatesContextProps | null>(null);

export const Provider = ({state = {}, children}: StateProviderProps) => {
  const [states, setStates] = useState<Record<string, State[]>>(state);

  useEffect(() => setStates(state), [state]);

  const createState = (newState: State, scopeId: string) => {
    setStates((prevStates) => {
      const updatedState = {...prevStates};

      if (!updatedState[scopeId]) {
        updatedState[scopeId] = [];
      }

      updatedState[scopeId].push(newState);

      return updatedState;
    });
  };

  const editState = (id: string, newValue: State, scopeId: string) => {
    setStates((prevStates) => {
      const updatedState = {...prevStates};

      if (updatedState[scopeId]) {
        updatedState[scopeId] = updatedState[scopeId].map((state) => (state.id === id ? newValue : state));
      }

      return updatedState;
    });
  };

  const deleteState = (id: string) => {
    setStates((prevStates) => {
      const updatedState = {...prevStates};

      for (const scopeId in updatedState) {
        updatedState[scopeId] = updatedState[scopeId].filter((state) => state.id !== id);
      }

      return updatedState;
    });
  };
  return (
    <StateContext.Provider value={{state: states, createState, editState, deleteState}}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => {
  return useContext<StatesContextProps | null>(StateContext);
};

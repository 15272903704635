import React, {useMemo} from 'react';

import {Option} from '../../../../../../types';
import {MultiSelect} from '../../../../../MultiSelect';
import {TableProps} from '../../../../../Table';

type Props = {
  columns: TableProps<any>['columns'];
  onUpdate: (x: Option[]) => void;
  placeholder: string;
};

export const ColumnsKeywords = ({columns, onUpdate, placeholder}: Props) => {
  const options = useMemo(() => {
    return columns.map((column) => ({value: column.field as string, label: column.field as string}));
  }, [columns]);
  return (
    <MultiSelect
      onChange={(selectedColumns) => onUpdate(selectedColumns?.map((selectedColumn) => selectedColumn.value))}
      options={options}
      width="100%"
      values={options}
      placeholder={placeholder}
    />
  );
};

import {DesignSpec} from '../../../types';
import {migratePage} from '../utils';

import {BasicElements} from './basic';
import {LayoutElements} from './layout';

export * from './basic';
export * from './layout';

export const CommonElements = {
  ...BasicElements,
  ...LayoutElements,
} as const;

export const designSpec: DesignSpec = {
  version: 2,
  blockDefinition: CommonElements,
  migrate: migratePage,
};
